
.home-page-button {
    min-height: 300px;
    min-width: 300px;
    margin-left: 64px;
    margin-right: 64px;
    font-size: xx-large;
}

.home-page-button.ant-btn {
    border-radius: 10px;
}