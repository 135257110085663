
.content-center .ant-descriptions-item-label {
  font-size: 18px;
  font-weight: bold;
}

.content-center .ant-descriptions-item-content {
  font-size: 18px;
}

.ant-card-grid.order-header {
  width: 35%;
}

.ant-card-grid.order-items {
  width: 65%;
}

.ant-table-title {
  font-size: 16px;
  font-weight: bold;
  padding-top: 0;
}

.title-center .ant-card-head-title {
  padding-left: 166px;
}

.ant-list .ant-list-item-meta-title {
  font-size: 18px;
  line-height: 32px;
  font-weight: bold;
}

.ant-list-item-meta-description {
  font-size: 16px;
}

.ant-modal-title {
  font-size: 18px;
}

.anticon.anticon-close.ant-modal-close-icon {
  font-size: 20px;
}
