@import '~antd/dist/antd.less';

.app {
    min-height: 100vh;
}

.ant-layout-header {
    line-height: 0;
}

.ant-layout-content {
    display: flex;
}

.content {
    min-height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.color-primary {
    color: #c40d16
}

.ant-breadcrumb {
    font-size: 18px;
}

.ant-card-head-title {
    font-size: 20px;
    padding: 8px 0;
}

.ant-card-grid {
    padding: 16px
}

.ant-modal-body {
    padding: 12px
}




@primary-color: #c40d16;