body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

.content-center {
  text-align: center;
}

.ant-input-lg {
  font-size: 32px;
  letter-spacing: 16px;
  text-transform: uppercase;
  text-align: center;
}

.compact .ant-input-lg {
  letter-spacing: initial;
}

.ant-input-clear-icon {
  font-size: 32px;
}

.ant-btn-lg.screen-button {
  width: 200px;
  height: 70px;
  font-size: 24px;
}

.ant-btn-lg.screen-button-sm {
  width: 160px;
  height: 50px;
  font-size: 20px;
}

.order-display .ant-descriptions-title {
  font-size: 36px;
}

.order-display .ant-descriptions-item-label {
  font-size: 20px;
  font-weight: bold;
}

.order-display .ant-descriptions-item-content {
  font-size: 20px;
}

.input-label {
  font-size: 20px;
  margin-right: 16px;
}
