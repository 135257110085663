.picking-note .ant-card-body {
    padding: 8px
}

.ant-table.ant-table-small .ant-table-tbody td.ant-table-cell.picking-box {
    padding-bottom: 0px;
}

.picking-note .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
    padding: 4px 4px;
}

.picking-note .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
    padding: 4px 4px;
}