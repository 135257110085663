
.header {
    position: sticky;
    top: 0;
    box-shadow: 0 1px 4px 0 rgb(0 21 41 / 12%);
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .language {
    position: absolute;
    right: 32px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}