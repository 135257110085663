
.card-slim .ant-card-body {
  padding: 0;
}

.ant-card-type-inner .ant-card-body {
  padding: 8px;
}

.ant-input-number-input {
  width: 100px;
  text-align: center;
}